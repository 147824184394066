import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxComponent } from './box/box.component';
import { ImageRenderComponent } from './image-render/image-render.component';
import { TitleCustomComponent } from './title-custom/title-custom.component';
import { ButtonCustomComponent } from './button/button-custom/button-custom.component';
import { TranslateModule } from '@ngx-translate/core';
import { SquareButtonComponent } from './button/square-button/square-button.component';
import { LanguageSelectComponent } from './dropdown/language-select/language-select.component';
import { OptionComponent } from './dropdown/option/option.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SpinnerComponent } from './spinner/spinner.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormErrorComponent } from './form-field/form-error/form-error.component';
import { RouterModule } from '@angular/router';
import { DetailComponent } from './detail/detail.component';
import { FileInputComponent } from './file-input/file-input.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AvatarComponent } from './avatar/avatar.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { FilterByDropdownComponent } from './dropdown/filter-by-dropdown/filter-by-dropdown.component';
import { GlobalDropdownComponent } from './dropdown/global-dropdown/global-dropdown.component';
import { AppRatingComponent } from './app-rating/app-rating.component';
import { HealthCreditsPanelComponent } from './health-credits-panel/health-credits-panel.component';
import { InputDropdownComponent } from './dropdown/input-dropdown/input-dropdown.component';
import { CheckboxComponent } from './form-field/checkbox/checkbox.component';
import { CountryDropdownComponent } from './dropdown/country-dropdown/country-dropdown.component';
import { PhoneNumberDropdownComponent } from './dropdown/phone-number-dropdown/phone-number-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PictureOfHcpComponent } from './picture-of-hcp/picture-of-hcp.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PatientTileComponent } from './patient-tile/patient-tile.component';
import { HealthcareProvidersTileComponent } from './healthcare-providers-tile/healthcare-providers-tile.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { MaterialConfirmDialogComponent } from './material-confirm-dialog/material-confirm-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  ClickOutsideDirective,
  DndDirective,
  LazyImgDirective,
} from '../core/directives/directive';
import { WebcamModule } from 'ngx-webcam';
import { WebcamComponent } from './webcam/webcam.component';
import { SearchDropdownComponent } from './dropdown/search-dropdown/search-dropdown.component';
import { GaugeComponent } from './gauge/gauge.component';
import { MenuRoleSelectComponent } from './menu-role-select/menu-role-select.component';
import { InputComponent } from './form-field/input/input.component';
import { TransactionComponent } from './transaction/transaction.component';
import { CountryMapComponent } from './country-map/country-map.component';
import { PopoverInfoComponent } from './popover-info/popover-info.component';
import { MobileCountryComponent } from './mobile-country/mobile-country.component';
import { TreatmentDocumentsComponent } from './treatment-documents/treatment-documents.component';
import { RecommendatedModuleComponent } from './recommendated-module/recommendated-module.component';
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { ChartsComponent } from './charts/charts.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DiscountComponent } from './discount/discount.component';
import { CountrySelectionComponent } from './dropdown/country-dropdown/components/country-selection/country-selection.component';
import { HealthcareModuleTileComponent } from './healthcare-module-tile/healthcare-module-tile.component';
import { PersonCardComponent } from './person-card/person-card.component';
import { AcceptedRecommendationComponent } from './accepted-recommendation/accepted-recommendation.component';
import { PaymentWaitingInfoComponent } from './payment-waiting-info/payment-waiting-info.component';
import { CreatePatientModalComponent } from './patient/create-patient-modal/create-patient-modal.component';
import { PackageRecommendationComponent } from './package-recommendation/package-recommendation.component';
import { TopUpComponent } from './top-up/top-up.component';
import { CustomRangeCalendarComponent } from './custom-range-calendar/custom-range-calendar.component';
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { InfoComponentComponent } from './info-component/info-component.component';
import { FloatingActionButtonComponent } from './floating-action-button/floating-action-button.component';
import { CreatePatientComponent } from './patient/create-patient/create-patient.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BonusAlertComponent } from './bonus-alert/bonus-alert.component';
import { AppEvaluationComponent } from './app-evaluation/app-evaluation.component';
import { WaitingTutorialModalComponent } from './waiting-tutorial-modal/waiting-tutorial-modal.component';
import { AfricaSvgComponent } from './africa-svg/africa-svg.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PreLoginInfosComponent } from './login/pre-login-infos/pre-login-infos.component';
import { LoginModalComponent } from './login/login-modal/login-modal.component';
import { ImagesViewerComponent } from './images-viewer/images-viewer.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { NoContentComponent } from './no-content/no-content.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { TranslationsFieldComponent } from './form-field/translations-field/translations-field.component';
import { CityDropdownComponent } from './dropdown/city-dropdown/city-dropdown.component';
import { CitySelectionComponent } from './dropdown/city-dropdown/components/city-selection/city-selection.component';
import { TimeFormFieldComponent } from './form-field/time-form-field/time-form-field.component';
import { TableComponent } from './table/table.component';
import { RoleSelectionModalComponent } from './menu-role-select/role-selection-modal/role-selection-modal.component';
import { MatRippleModule } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SpecialtyModalComponent } from './specialitie-modal/specialitie-modal.component';
import { StateDropdownComponent } from './dropdown/state-dropdown/state-dropdown.component';
import { StateSelectionComponent } from './dropdown/state-dropdown/components/state-selection/state-selection.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HcpPaymentMethodComponent } from './hcp-payment-method/hcp-payment-method.component';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
  provideEnvironmentNgxMask,
} from 'ngx-mask';
import { LoginComponent } from './login/login.component';
import { AppUsageGuideComponent } from './app-usage-guide/app-usage-guide.component';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { ConversationChannelComponent } from './conversation-channel/conversation-channel.component';
import { LottieIconsComponent } from './lottie-icons/lottie-icons.component';
import { LottieDirective } from 'ngx-lottie';
import { GoogleLoginComponent } from './login/google-login/google-login.component';
import { GoogleOneTapComponent } from './login/google-one-tap/google-one-tap.component';

const maskConfig: Partial<IConfig> = {
  validation: true,
};
@NgModule({
  declarations: [
    BoxComponent,
    ImageRenderComponent,
    TitleCustomComponent,
    ButtonCustomComponent,
    SquareButtonComponent,
    LanguageSelectComponent,
    OptionComponent,
    SpinnerComponent,
    FormFieldComponent,
    InputComponent,
    FormErrorComponent,
    DetailComponent,
    FileInputComponent,
    FileUploadComponent,
    AvatarComponent,
    StatusBadgeComponent,
    FilterByDropdownComponent,
    GlobalDropdownComponent,
    AppRatingComponent,
    HealthCreditsPanelComponent,
    InputDropdownComponent,
    CheckboxComponent,
    CountryDropdownComponent,
    PhoneNumberDropdownComponent,
    PictureOfHcpComponent,
    PaginationComponent,
    PatientTileComponent,
    HealthcareProvidersTileComponent,
    MaterialConfirmDialogComponent,
    ClickOutsideDirective,
    WebcamComponent,
    SearchDropdownComponent,
    GaugeComponent,
    MenuRoleSelectComponent,
    TransactionComponent,
    CountryMapComponent,
    PopoverInfoComponent,
    MobileCountryComponent,
    TreatmentDocumentsComponent,
    RecommendatedModuleComponent,
    SkeletonLoaderComponent,
    ChartsComponent,
    DiscountComponent,
    CountrySelectionComponent,
    HealthcareModuleTileComponent,
    PersonCardComponent,
    AcceptedRecommendationComponent,
    PaymentWaitingInfoComponent,
    CreatePatientModalComponent,
    PackageRecommendationComponent,
    TopUpComponent,
    DndDirective,
    CustomRangeCalendarComponent,
    InfoComponentComponent,
    FloatingActionButtonComponent,
    LazyImgDirective,
    CreatePatientComponent,
    BonusAlertComponent,
    AppEvaluationComponent,
    WaitingTutorialModalComponent,
    AfricaSvgComponent,
    PreLoginInfosComponent,
    LoginModalComponent,
    ImagesViewerComponent,
    NoContentComponent,
    SearchBarComponent,
    TranslationsFieldComponent,
    StateDropdownComponent,
    CityDropdownComponent,
    CitySelectionComponent,
    StateSelectionComponent,
    TimeFormFieldComponent,
    TableComponent,
    RoleSelectionModalComponent,
    SpecialtyModalComponent,
    HcpPaymentMethodComponent,
    LoginComponent,
    AppUsageGuideComponent,
    AudioRecorderComponent,
    ConversationChannelComponent,
    LottieIconsComponent,
    GoogleLoginComponent,
    GoogleOneTapComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InlineSVGModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    WebcamModule,
    LeafletModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatStepperModule,
    CarouselModule,
    MatProgressBarModule,
    MatRippleModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PipesModule,
    LottieDirective,
  ],
  exports: [
    BoxComponent,
    ImageRenderComponent,
    TitleCustomComponent,
    ButtonCustomComponent,
    TranslateModule,
    SquareButtonComponent,
    LanguageSelectComponent,
    OptionComponent,
    SpinnerComponent,
    FormFieldComponent,
    InputComponent,
    FormErrorComponent,
    DetailComponent,
    FileInputComponent,
    FileUploadComponent,
    AvatarComponent,
    StatusBadgeComponent,
    FilterByDropdownComponent,
    GlobalDropdownComponent,
    AppRatingComponent,
    HealthCreditsPanelComponent,
    InputDropdownComponent,
    CheckboxComponent,
    CountryDropdownComponent,
    PhoneNumberDropdownComponent,
    PictureOfHcpComponent,
    PaginationComponent,
    PatientTileComponent,
    ClickOutsideDirective,
    HealthcareProvidersTileComponent,
    MaterialConfirmDialogComponent,
    WebcamComponent,
    SearchDropdownComponent,
    GaugeComponent,
    MenuRoleSelectComponent,
    TransactionComponent,
    CountryMapComponent,
    PopoverInfoComponent,
    TreatmentDocumentsComponent,
    RecommendatedModuleComponent,
    SkeletonLoaderComponent,
    ChartsComponent,
    DiscountComponent,
    CountrySelectionComponent,
    HealthcareModuleTileComponent,
    PersonCardComponent,
    AcceptedRecommendationComponent,
    PaymentWaitingInfoComponent,
    CreatePatientModalComponent,
    PackageRecommendationComponent,
    TopUpComponent,
    DndDirective,
    CustomRangeCalendarComponent,
    InfoComponentComponent,
    FloatingActionButtonComponent,
    LazyImgDirective,
    CreatePatientComponent,
    BonusAlertComponent,
    AppEvaluationComponent,
    AfricaSvgComponent,
    PreLoginInfosComponent,
    LoginModalComponent,
    ImagesViewerComponent,
    NoContentComponent,
    SearchBarComponent,
    TranslationsFieldComponent,
    CityDropdownComponent,
    StateDropdownComponent,
    StateSelectionComponent,
    TimeFormFieldComponent,
    TableComponent,
    RoleSelectionModalComponent,
    SpecialtyModalComponent,
    HcpPaymentMethodComponent,
    LoginComponent,
    AppUsageGuideComponent,
    AudioRecorderComponent,
    ConversationChannelComponent,
    LottieIconsComponent,
    GoogleLoginComponent,
    GoogleOneTapComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    provideNgxMask(),
    provideEnvironmentNgxMask(maskConfig),
  ],
})
export class SharedComponentsModule {}
