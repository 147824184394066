import { PaymentMobileService } from './core/services/paymentMobile.service';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { HttpLoaderFactory } from './core/utils/loader-factory';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { StoreModule } from '@ngrx/store';
import { metaReducers, rootReducer } from './core/state/reducer';
import {
  VisibilityStateService,
  BreakpointMatcherService,
  LanguageService,
  CustomNavigationService,
  StorageService,
  ModalService,
  PhoneNumberService,
} from './core/services/services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SessionService } from './core/services/services';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  TokenInterceptor,
  ErrorInterceptor,
} from './core/interceptors/interceptors';
import { ToastrModule } from 'ngx-toastr';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {
  RestAuthentificationService,
  BuyedPackageService,
  CampaignService,
  ContactService,
  CurrencyService,
  DashboardService,
  ErrorsService,
  FileService,
  GroupService,
  HealthPackageService,
  HealthProviderService,
  InvoiceService,
  NoteService,
  PaymentService,
  ExportFileService,
  RestLanguageService,
  RestRoleService,
  SettingsService,
  TransactionService,
  UseCaseBuyedPackageService,
  UseCaseGroupService,
  UseCaseHcpService,
  UserService,
  RecommendationService,
  AdminMonitoringService,
  UseCaseCampaignService,
  IntouchService,
  UsecasePdfService,
  UseCaseUserService,
  DiscountService,
  CoporateMonitoringService,
  UseCaseRoleService,
  UseCaseTotpService,
  ScrappingService,
} from './core/rest-services/rest-services';
import { NotificationService } from './core/services/notification.service';
import { PipesModule } from './core/pipes/pipes.module';
import { GlobalErrorHandler } from './core/services/globalErrorHandler.service';
import { UseCaseDiscountService } from './core/rest-services/usecases/usecase-discount.service';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { BenefitService } from './core/rest-services/usecases/benefit.service';
import { SupportOnlineComponent } from './pages/shared-pages/support-online/support-online.component';
import { AppUpdateService } from './core/services/app-update.service';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { UseCaseHealthPackageService } from './core/rest-services/usecases/usecase-healthpackage.service';
import { OCRService } from './core/rest-services/usecases/ocr.service';
import { HcpDashboardService } from './core/rest-services/usecases/HcpDashboard.service';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { AiService } from './core/rest-services/ai.service';
import { PublicApiService } from './core/rest-services/public-api/hcp-api.service';
import { KimboMatIconRegistry } from './core/services/kimbo-mat-icon-registry.service';
import { AdminContainerModule } from './pages/admin-container/admin-containers.module';
import { UseCaseSessionService } from './core/rest-services/usecases/usecase-session.service';
import { CountryService } from './core/rest-services/resources/country.service';
import { AdminRoleService } from './core/services/admin-role.service';
import { UseCaseCountryService } from './core/rest-services/usecases/usecase-country.service';
import { RestAdminRoleService } from './core/rest-services/resources/admin-role.service';
import { UseCaseAdminRoleService } from './core/rest-services/usecases/usecase-admin-role.service';
import { BenefitContainerModule } from './pages/containers/benefit-container/benefit-container.module';
import { AuthentificationService } from './core/services/authentication.service';
import { HcpService } from './core/services/hcp.service';
import { BottomSheetService } from './core/services/bottom-sheet.service';
import { ChatService } from './core/rest-services/chat.service';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';

@Injectable()
export class NoopService {}

@NgModule({
  declarations: [AppComponent, SupportOnlineComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the application is stable
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    InlineSVGModule.forRoot(),
    StoreModule.forRoot(
      {
        root: rootReducer,
      },
      {
        metaReducers,
      }
    ),
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    PipesModule,
    SharedComponentsModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatIconModule,
    AdminContainerModule,
    BenefitContainerModule,
  ],
  providers: [
    AdminRoleService,
    VisibilityStateService,
    BreakpointMatcherService,
    LanguageService,
    CustomNavigationService,
    SessionService,
    CampaignService,
    ContactService,
    CurrencyService,
    FileService,
    GroupService,
    HealthProviderService,
    HealthPackageService,
    RestLanguageService,
    TransactionService,
    UserService,
    IntouchService,
    ModalService,
    RestAuthentificationService,
    AuthentificationService,
    PaymentService,
    ExportFileService,
    BuyedPackageService,
    UseCaseGroupService,
    UseCaseHcpService,
    UseCaseBuyedPackageService,
    NoteService,
    DashboardService,
    RestRoleService,
    InvoiceService,
    SettingsService,
    NotificationService,
    ErrorsService,
    UseCaseUserService,
    PaymentMobileService,
    UsecasePdfService,
    AdminMonitoringService,
    UseCaseCampaignService,
    RecommendationService,
    DiscountService,
    UseCaseDiscountService,
    CoporateMonitoringService,
    UseCaseHealthPackageService,
    HcpDashboardService,
    BenefitService,
    OCRService,
    UseCaseRoleService,
    PhoneNumberService,
    UseCaseTotpService,
    AiService,
    PublicApiService,
    KimboMatIconRegistry,
    UseCaseCountryService,
    UseCaseSessionService,
    RestAdminRoleService,
    UseCaseAdminRoleService,
    CountryService,
    ScrappingService,
    HcpService,
    BottomSheetService,
    ChatService,
    provideLottieOptions({
      player: () => player,
    }),
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    StorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: StorageService) => () => ds.init(),
      deps: [StorageService],
      multi: true,
    },
    // { provide: Sentry.TraceService, deps: [Router] },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    environment.name !== 'development'
      ? { provide: ErrorHandler, useClass: GlobalErrorHandler }
      : NoopService,
    AppUpdateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
